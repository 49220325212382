import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const SupportPage = () => (
  <div>
      <SEO 
        title="24x7 Support" 
        description="Do you want tech help and support? Our support department is at all times prepared to assist."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32 mt-10">
      <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
                <p className="section-title subpixel-antialiased text-5xl">
                  <span className="block text-indigo-600 xl:inline">24x7</span> 
                  Support 
                  <span className="block xl:inline animate-bounce"> <span role="img" aria-label="emoji">✨ </span></span>
                </p>
              <p className="section-after-title subpixel-antialiased">
              Do you want tech help and support? Our support department is at all times prepared to assist.
              </p>
          </div>
      </div>
    </div>

    <div className="py-32 bg-gray-50">
        <div className="my-2 px-2 w-full overflow-hidden text-center">
                <h2>Help and support Channels</h2>
                
            </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
        
        <div className="rounded-xl bg-indigo-800 flex flex-col p-8 shadow-lg">
            <div className="w-16 h-16 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0 p-4">
            <svg className="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
            </svg>
            </div>
            <div className="flex-grow text-white">
            <h2 className="text-xl title-font font-medium mb-3">Video Tutorials</h2>
            <p className="leading-relaxed text-sm text-justify">
            Learn right away the techniques to edit a document or transfer a database via our variety of how to video classes.
            </p>
            </div>
        </div>

        <div className="rounded-xl bg-indigo-800 flex flex-col p-8 shadow-lg">
            <div className="w-16 h-16 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0 p-4">
            <svg className="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            </div>
            <div className="flex-grow text-white">
            <h2 className="text-xl title-font font-medium mb-3">A F.A.Q. Section</h2>
            <p className="leading-relaxed text-sm text-justify">
            Our Help Center provides a long lineup of Frequently Answered Questions that deal with all the parts of our Web Control Panel.
            </p>
            </div>
        </div>

        <div className="rounded-xl bg-indigo-800 flex flex-col p-8 shadow-lg">
            <div className="w-16 h-16 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0 p-4">
            <svg className="w-24 h-24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
            </svg>
            </div>
            <div className="flex-grow text-white">
            <h2 className="text-xl title-font font-medium mb-3">A Ticketing System</h2>
            <p className="leading-relaxed text-sm text-justify">
            The tech support department is working 24/7/365 to reply to your concerns. You are going to be given an answer in no more than 60 minutes right after the ticket submission.
            </p>
            </div>
        </div>
      </div>
    </div>

    <div className="py-32">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-1/4 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="Website Hosting Assistance" src="https://sheephostingbay.duoservers.com/template2/img/shared-hosting-support.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Website Hosting Assistance</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">If you have concerns employing our website hosting services or would 
                        like more resources for your sites, just let us know and we’ll give you the best ideas.</p>
                    </div>
                    <div className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                        <div>Learn more</div>
                        <div className="ml-1 text-indigo-500">
                            <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/4 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="VPS Assistance" src="https://sheephostingbay.duoservers.com/template2/img/vps-hosting-support.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">VPS Assistance</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">If you run into troubles utilizing your SolusVM administration panel, or with any of the 
                        applications your VPS has been pre–configured with, just inform us.</p>
                    </div>
                    <div className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                        <div>Learn more</div>
                        <div className="ml-1 text-indigo-500">
                            <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/4 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="Dedicated Hosting Help" src="https://sheephostingbay.duoservers.com/template2/img/dedicated-hosting-support.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Dedicated Hosting Help</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">We’ll be present night and day to help you with any given server management procedure or 
                        to help you alter the current setup of your dedicated hosting server.</p>
                    </div>
                    <div className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                        <div>Learn more</div>
                        <div className="ml-1 text-indigo-500">
                            <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/4 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="Semi-Dedicated Hosting" src="https://sheephostingbay.duoservers.com/template2/img/semi-dedicated-hosting-support.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Semi-Dedicated Hosting</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Should you have any issues with your semi-dedicated server or require assistance with 
                        a particular project, don't be reluctant to call our expert.. </p>
                    </div>
                    <div className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                        <div>Learn more</div>
                        <div className="ml-1 text-indigo-500">
                            <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default SupportPage
